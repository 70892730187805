var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'enable_rules_command',
          'hasAnchor': true,
        }
      }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.chatState.chat.config.enable_rules_command)?_c('nested-content',[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'rules',
              'placeholders': _vm.BASE_PLACEHOLDERS,
              'targetToUpload': function () { return _vm.groupUploadTarget; },
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
              'base-api-url': 'api.chatkeeper.app/cabinet',
              'hasAnchor': true,
            },
          }}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }