















































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types';
import { Component, Mixins } from 'vue-property-decorator'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { InputSetups } from "../../mixins/input-setups";
import Placeholders from "@/mixins/placeholders/placeholders";

@Component({
  components: {
    CenteredColumnLayout,
    PageTitle
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class ChatRules extends Mixins(UseFields, InputSetups, Placeholders) {

}
